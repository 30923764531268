/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Button = styled.button`
  position: relative;
  display: block;
  padding: 12px 28px;
  background: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.lightYellow};
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1.4rem;
  width: 100%;
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  letter-spacing: 1px;
  ${({ theme }) => theme.mq.s} {
    padding: 15px 40px;
    font-size: 1.6rem;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 25px solid #f99d1c;
    z-index: 1;
    ${({ theme }) => theme.mq.s} {
      border-top-width: 26px;
      border-bottom-width: 26px;
      border-left-width: 30px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 25px solid #ea5b0c;
    z-index: 1;
    ${({ theme }) => theme.mq.s} {
      border-top-width: 26px;
      border-bottom-width: 26px;
      border-right-width: 30px;
    }
  }
  ${({ secondary }) =>
    secondary &&
    css`
      &:before {
        border-left-color: #d0ecf0;
      }
      &:after {
        border-right-color: #d0ecf0;
      }
    `}
  ${({ submit }) =>
    submit &&
    css`
      position: relative;
      height: 50px;
      padding: 16px;
      border: none;
      background: #f36a36;
      color: ${({ theme }) => theme.colors.white};
      &:before {
        content: '';
        position: absolute;
        top: 27%;
        left: 45%;
        width: 24px;
        height: 24px;
        border: 3px solid ${({ theme }) => theme.white};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        background: none;
        opacity: ${({ disabled }) => (disabled ? '1' : '0')};
        animation: ${spin} 1s ease infinite;
      }
      &:after {
        display: none;
      }
    `}

    ${({ submitQuestion }) =>
    submitQuestion &&
    css`
      background: #f36a36;
      &:before {
        left: 0;
        border-top: 22px solid transparent;
        border-left: 35px solid #f99d1c;
      }
      &:after {
        right: 0;
        border-top: 22px solid transparent;
        border-right: 35px solid #09ac9b;
      }
    `}
`;

export default Button;

const ViewButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  width: 100%;
  max-width: 190px;
  height: 66px;
  border-radius: 30px;
  transition: 0.3s;
  background: transparent;
  cursor: pointer;
  ${({ theme }) => theme.mq.xs} {
    margin-left: 5px;
  }
  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.lightYellow};
    `}
  &:hover {
    background: ${({ theme, active }) =>
      active ? theme.colors.lightYellow : `#F7F7F7`};
  }
  svg {
    fill: ${({ theme }) => theme.colors.gray};
    transition: 0.3s;
  }
  span {
    font-size: 1.4rem;
    white-space: nowrap;
    margin-left: 9px;
    ${({ theme }) => theme.mq.s} {
      margin-left: 12px;
      font-size: 1.5rem;
    }
  }
  .d {
    fill: ${({ theme, active }) =>
      active ? theme.colors.lightGreen100 : '#888'};
    transition: 0.3s;
  }
  .a,
  .c {
    fill: none;
  }
  .a {
    stroke: ${({ theme, active }) =>
      active ? theme.colors.lightGreen100 : '#888'};
    transition: 0.3s;
    stroke-width: 2px;
  }
  .b {
    stroke: none;
  }
`;

export const ViewButton = ({ children, list, ...props }) => (
  <ViewButtonWrapper {...props}>
    {list ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="18"
        viewBox="0 0 35 22"
      >
        <rect className="d" width="4" height="4" />
        <rect className="d" width="4" height="4" transform="translate(0 9)" />
        <rect className="d" width="4" height="4" transform="translate(0 18)" />
        <rect className="d" width="27" height="4" transform="translate(8)" />
        <rect className="d" width="27" height="4" transform="translate(8 9)" />
        <rect className="d" width="27" height="4" transform="translate(8 18)" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="29"
        viewBox="0 0 35 34"
      >
        <g className="a">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(0 19)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(20)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(20 19)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
      </svg>
    )}

    <span>{children}</span>
  </ViewButtonWrapper>
);
